.sprite-calendar {
    background-image: url(icons.png);
    background-position: -180px -97px;
    width: 22px;
    height: 22px;
}
.sprite-csv {
    background-image: url(icons.png);
    background-position: -180px 0px;
    width: 25px;
    height: 25px;
}
.sprite-hammer {
    background-image: url(icons.png);
    background-position: -60px -57px;
    width: 40px;
    height: 40px;
}
.sprite-layers {
    background-image: url(icons.png);
    background-position: -100px -57px;
    width: 40px;
    height: 40px;
}
.sprite-left {
    background-image: url(icons.png);
    background-position: -180px -25px;
    width: 24px;
    height: 24px;
}
.sprite-logo {
    background-image: url(icons.png);
    background-position: 0px 0px;
    width: 180px;
    height: 57px;
}
.sprite-logo2 {
    background-image: url(icons.png);
    background-position: 0px -57px;
    width: 60px;
    height: 60px;
}
.sprite-logo3 {
    background-image: url(icons.png);
    background-position: -140px -57px;
    width: 40px;
    height: 40px;
}
.sprite-profit {
    background-image: url(icons.png);
    background-position: 0px -117px;
    width: 40px;
    height: 40px;
}
.sprite-refresh {
    background-image: url(icons.png);
    background-position: -180px -49px;
    width: 24px;
    height: 24px;
}
.sprite-right {
    background-image: url(icons.png);
    background-position: -180px -73px;
    width: 24px;
    height: 24px;
}
.sprite-search {
    background-image: url(icons.png);
    background-position: -120px -117px;
    width: 36px;
    height: 36px;
}
.sprite-select-white {
    background-image: url(icons.png);
    background-position: -180px -119px;
    width: 11px;
    height: 5px;
}
.sprite-select {
    background-image: url(icons.png);
    background-position: -191px -119px;
    width: 11px;
    height: 5px;
}
.sprite-soil {
    background-image: url(icons.png);
    background-position: -40px -117px;
    width: 40px;
    height: 40px;
}
.sprite-upload {
    background-image: url(icons.png);
    background-position: -80px -117px;
    width: 40px;
    height: 40px;
}
