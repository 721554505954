@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.table-header {
  @apply px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}
.table-header-right {
  @apply px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.table-data {
  @apply px-3 py-1 text-xs truncate max-w-xs;
}

.btn-sm {
  @apply inline-block px-3 py-1 rounded shadow-lg lowercase text-white select-none cursor-pointer;
}

.btn {
  @apply inline-block px-3 py-1 rounded shadow-lg tracking-wider uppercase text-white select-none cursor-pointer outline-none;
}

.btn-disabled {
  @apply inline-block px-3 py-1 rounded tracking-wider uppercase text-white select-none bg-gray-600;
}

.btn:focus {
  @apply outline-none ring;
}

.btn-error {
  @apply bg-error-500;
}

.btn-error:hover {
  @apply bg-error-600;
}

.btn-error:active {
  @apply bg-error-700;
}

.btn-success {
  @apply bg-success-500;
}

.btn-success:hover {
  @apply bg-success-600;
}

.btn-success:active {
  @apply bg-success-700;
}

.table-center {
  @apply table-cell w-screen h-screen text-center align-middle;
}

.checkbox {
  @apply focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded
}
.checkbox-cross {
  @apply focus:ring-red-500 h-4 w-4 text-gray-300 border-gray-300 rounded relative;
  color: red;
}
.checkbox-cross:checked {
  background: red;
}
.checkbox-cross:checked:after {
  @apply text-white absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  content: "X";
}

.alternate-row:nth-child(even) {
  @apply bg-gray-100
}

.ghost-center:before {
  content: " ";
  @apply align-middle inline-block h-full w-0;
}

.ghost-center {
  letter-spacing: -0.31em;
  word-spacing: -0.43em;
  @apply inline-block align-middle w-full text-center whitespace-nowrap;
}

.input {
  @apply shadow-sm block w-full outline-none sm:text-sm p-2 rounded-md text-gray-800 border border-2;
}

.ghost-center > * {
  @apply inline-block align-middle;
}

.input:focus {
  @apply border-primary-500 ring-primary-500;
}

.table-primary {
  @apply w-full rounded-t border py-1 relative;
}

.input-sm {
  @apply max-w-lg block w-full outline-none p-1 rounded text-gray-800 sm:max-w-xs text-sm border border-2;
}

.table-primary tr:nth-child(even) {
  @apply bg-gray-100;
}
.input-sm:focus {
  @apply border-primary-500 ring-primary-500;
}

.input-select {
  @apply max-w-lg block focus:ring-primary-500 focus:border-primary-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md text-gray-600;
}

.table-primary th {
  @apply text-white text-white font-normal text-left px-2;
}

.input-error {
  @apply max-w-lg block ring-red-500 border-red-500 focus:ring-primary-500 focus:border-primary-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md text-gray-600;
}

.table-primary td {
  @apply border-b border px-2;
}

.sm-input-select {
  @apply max-w-md block focus:ring-primary-500 focus:border-primary-500 w-full shadow-sm sm:max-w-xs text-xs border-gray-300 rounded-md p-1 text-gray-600;
}

.btn-circle {
  @apply w-8 h-8 text-xl rounded-full text-white text-center cursor-pointer;
  line-height: 2rem;
}

.inactive {
  text-decoration: line-through red double;
}

.bg-excel {
  background-color: #1d6f42;
}

.checkbox-mark:after {
  width: 30%;
  height: 70%;
  content: " ";
  left: 35%;
  top: 8%;
  transform: rotate(45deg);
  @apply hidden absolute border-white border-r-2 border-b-2;
}

input:checked ~ .mark {
  @apply bg-primary-500;
}

.checkbox-mark:hover, input:focus ~ .checkbox-mark {
  @apply ring outline-none;
}

input:checked ~ .checkbox-mark:after {
  @apply block;
}

.btn-primary {
  @apply bg-primary-500;
}

input:checked ~ .checkbox-mark {
  @apply bg-primary;
}

.btn-primary:hover {
  @apply bg-primary-600;
}

.btn-primary:active {
  @apply bg-primary-700;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(100, 100, 100, 0.4);
  backdrop-filter: blur(4px);
}
.lightoverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(100, 100, 100, 0.1);
  backdrop-filter: blur(4px);
}

.icon {
  @apply text-center mx-1 p-1 cursor-pointer;
}

.rotate {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
}

.icon:hover, .router-link-active {
  @apply bg-primary-500;
}
.icon:hover .icon-primary, .router-link-active .icon-primary {
  @apply text-primary-500 fill-current;
}
.icon:hover .icon-secondary, .router-link-active .icon-secondary {
  @apply text-secondary-500 fill-current;
}

[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  @apply appearance-none w-4 h-4 bg-primary-600 rounded-full bg-center;
}

.icon-primary {
  fill: none;
  stroke: currentColor;
  @apply text-gray-600;
}

.icon-secondary {
  fill: none;
  stroke: currentColor;
  @apply text-gray-900;
}

.svg-primary {
  @apply text-primary-500 fill-current;
}

.svg-secondary {
  @apply text-secondary-500 fill-current;
}

h1 {
  background-color: #eee;
  color: orange;
  user-select: none;
}

.breeding-rhombus-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  transform: rotate(45deg);
}

.breeding-rhombus-spinner, .breeding-rhombus-spinner * {
  box-sizing: border-box;
}

.breeding-rhombus-spinner .rhombus {
  height: calc(65px / 7.5);
  width: calc(65px / 7.5);
  animation-duration: 2000ms;
  top: calc(65px / 2.3077);
  left: calc(65px / 2.3077);
  position: absolute;
  animation-iteration-count: infinite;
}

.breeding-rhombus-spinner .rhombus:nth-child(2n+0) {
  margin-right: 0;
}

.breeding-rhombus-spinner .rhombus.child-1 {
  animation-name: breeding-rhombus-spinner-animation-child-1;
  animation-delay: calc(100ms * 1);
}

.breeding-rhombus-spinner .rhombus.child-2 {
  animation-name: breeding-rhombus-spinner-animation-child-2;
  animation-delay: calc(100ms * 2);
}

.breeding-rhombus-spinner .rhombus.child-3 {
  animation-name: breeding-rhombus-spinner-animation-child-3;
  animation-delay: calc(100ms * 3);
}

.breeding-rhombus-spinner .rhombus.child-4 {
  animation-name: breeding-rhombus-spinner-animation-child-4;
  animation-delay: calc(100ms * 4);
}

.breeding-rhombus-spinner .rhombus.child-5 {
  animation-name: breeding-rhombus-spinner-animation-child-5;
  animation-delay: calc(100ms * 5);
}

.breeding-rhombus-spinner .rhombus.child-6 {
  animation-name: breeding-rhombus-spinner-animation-child-6;
  animation-delay: calc(100ms * 6);
}

.breeding-rhombus-spinner .rhombus.child-7 {
  animation-name: breeding-rhombus-spinner-animation-child-7;
  animation-delay: calc(100ms * 7);
}

.breeding-rhombus-spinner .rhombus.child-8 {
  animation-name: breeding-rhombus-spinner-animation-child-8;
  animation-delay: calc(100ms * 8);
}

.breeding-rhombus-spinner .rhombus.big {
  height: calc(65px / 3);
  width: calc(65px / 3);
  animation-duration: 2000ms;
  top: calc(65px / 3);
  left: calc(65px / 3);
  animation: breeding-rhombus-spinner-animation-child-big 2s infinite;
  animation-delay: 0.5s;
}

@keyframes breeding-rhombus-spinner-animation-child-1 {
  50% {
    transform: translate(-325%, -325%);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-2 {
  50% {
    transform: translate(0, -325%);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-3 {
  50% {
    transform: translate(325%, -325%);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-4 {
  50% {
    transform: translate(325%, 0);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-5 {
  50% {
    transform: translate(325%, 325%);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-6 {
  50% {
    transform: translate(0, 325%);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-7 {
  50% {
    transform: translate(-325%, 325%);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-8 {
  50% {
    transform: translate(-325%, 0);
  }
}
@keyframes breeding-rhombus-spinner-animation-child-big {
  50% {
    transform: scale(0.5);
  }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 0.5s infinite;
  -moz-animation: flickerAnimation 0.5s infinite;
  -o-animation: flickerAnimation 0.5s infinite;
  animation: flickerAnimation 0.5s infinite;
}

.loginBackground {
  background-image: url("../public/images/back.jpg");
  background-size: cover;
}
